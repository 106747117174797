.msg {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg p {
  transform: scale(1);
  text-align: center;
  line-height: 150rem;
  color: #fff;
  padding: 0 60rem;
  animation: fadeInSize .4s;
  border-radius: 25rem;
  background: #5A5A5A;
  font-size: 39rem;
}

.msg p.hiding {
  transition: all .4s;
  transform: scale(0);
  opacity: 0;
}

@keyframes fadeInSize {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}