.main {
  width: 1125rem;
  padding: 140rem 0;
}

.card {
  width: 1053rem;
  margin: 0 auto;
  padding-bottom: 123rem;
  border-radius: 30rem;
  background: #2D2C2B;
  position: relative;
  text-align: center;
}

.card.empty {
  height: 1601rem;
  position: relative;
}

.card.empty div {
  padding-top: 200rem;
}

.card.empty p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 0 !important;
}

.card>img {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 229rem;
}

.card p:nth-child(2) {
  padding-top: 160rem;
  font-size: 60rem;
  font-weight: 500;
  color: #DFBF87;
}

.card p:nth-child(3) {
  font-family: Oswald;
  font-size: 118rem;
  font-weight: 500;
  color: #7FFF9A;
  padding: 20rem 0 25rem;
}

.card p:nth-child(3) i {
  font-size: 45rem;
  margin-left: 15rem;
}

.card p:nth-child(4) {
  font-size: 45rem;
  font-weight: 300;
  color: #A3968A;
}

.card .qrcode {
  width: 492rem;
  height: 492rem;
  margin: 40rem auto 30rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .qrcode canvas {
  width: 444.95rem !important;
  height: 444.95rem !important;
}

.card .saveImg {
  display: block;
  width: 492rem;
  height: 90rem;
  text-align: center;
  line-height: 90rem;
  margin: 0 auto 30rem;
  border-radius: 90rem;
  background: #514D46;
  border: 1px solid #DFBF87;
  font-size: 39rem;
  color: #DFBF87;
}

.card p:nth-child(7) {
  font-weight: 300;
  font-size: 45rem;
  color: #83796F;
}

.card p:nth-child(7) i {
  font-weight: 600;
  font-size: 45rem;
  color: #FFAC3B
}

.card p:nth-child(8) {
  padding: 34rem 0 54rem;
  font-size: 39rem;
  font-weight: 300;
  color: #83796F;
}

.card p:nth-child(8) i {
  font-size: 39rem;
  color: #978E84;
}

.card p:nth-child(8) span {
  display: inline-block;
  width: 102rem;
  height: 54rem;
  margin-left: 24rem;
  border-radius: 90rem;
  background: #3E3C39;
  text-align: center;
  line-height: 54rem;
  font-size: 30rem;
  font-weight: 300;
  color: #AC9D8E;
}

.card.empty p,
.card p:nth-child(9) {
  width: 838rem;
  height: 150rem;
  margin: 0 auto;
  border-radius: 90rem;
  background: #E4C283;
  font-size: 54rem;
  font-weight: 500;
  line-height: 150rem;
  color: #1F1E1C;
  text-align: center;
}

.footer {
  padding: 84rem 36rem 120rem;
}

.footer p:nth-child(1) {
  font-size: 42rem;
  color: #83796F;
}

.footer p:nth-child(2) {
  padding: 24rem 0 54rem;
  font-size: 39rem;
  font-weight: 300;
  color: #83796F;
}

.footer .download {
  display: flex;
  justify-content: space-between;
  height: 196rem;
  border-radius: 30rem;
  background: #2D2C2B;
  padding: 45rem;
}

.footer .download div {
  opacity: 1;
}

.footer .download img {
  width: 253rem;
}

.footer .download p {
  font-size: 36rem;
  font-weight: 300;
  color: #83796F;
}

.footer .download a {
  display: block;
  width: 420rem;
  height: 120rem;
  border-radius: 90rem;
  background: #514D46;
  border: 2rem solid #E4C283;
  font-size: 48rem;
  text-align: center;
  font-weight: 500;
  line-height: 120rem;
  color: #DFBF87;

}